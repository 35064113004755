.checkBoxLanguage {
  cursor: pointer;
  display: flex;
  gap: 12px;
  margin: 10px;
}
.generatedPdf {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
}
.generatedPdf > span {
  border: 1px solid gray;
  padding: 3px 6px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.darkMode .generatedPdf > span {
  border: 1px solid gray;
  padding: 3px 6px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.authModal {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}
.modalContainerJuridic {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}
.juridicHeader {
  position: relative;
  border-radius: 0.5rem;
}
.juridicContent {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
}

.ticketDetailsSpan {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}
.juridicButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #9ca3af;
  background-color: transparent;
}
.juridicButton:hover {
  color: #111827;
  background-color: #e5e7eb;
}
.closeSvg {
  width: 0.75rem;
  height: 0.75rem;
}
.closeModalButton {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.juridicFormContainer {
  padding: 1rem;
}

.juridicFormContent {
  margin-top: 1rem;
}
.passwordSpan {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.textFieldsContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}
.saveModificiatiButton {
  margin: 10px 0px 10px 0px !important;
  width: 100%;
}
.atasamenteLabel {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.filesContainer {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
}
.fileContent {
  display: flex;
  position: relative;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #d1d5db;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.descripritonIcon {
  margin-bottom: 0.5rem;
}
.typoSubitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40px;
}
.addAttachment {
  display: flex;
  position: relative;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #d1d5db;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.addIconJuridic {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .juridicContent {
    padding: 1.25rem;
  }
  .juridicFormContainer {
    padding: 1.25rem;
  }
  .textFieldsContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
