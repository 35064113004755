.nameButton {
  cursor: pointer;
  padding: 2px;
  border-radius: 3px;
}

.userDetailsDialog {
  padding: 10px;
  min-width: 400px;
}
