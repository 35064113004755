.greenCircle {
  color: rgb(40, 167, 69);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.redCircle {
  color: red;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
