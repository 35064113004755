:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #008ada;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #037cc236;
}

.MuiDataGrid-root {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}

.MuiDataGrid-cell {
  border-bottom: 1px solid #545454 !important;
}

.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover > .MuiDataGrid-cell {
  opacity: 1;
}

.MuiDataGrid-virtualScrollerContent .MuiDataGrid-cell {
  opacity: 0.5;
}

.darkMode .MuiDataGrid-columnHeaders {
  background: #303030;
}

.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #545454 !important;
}

.darkMode .MuiDataGrid-toolbarContainer {
  background: #303030;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.insertDataBox {
  background: #ffffff40;
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.backgroundBox {
  align-items: center;
}

.insertDataForm {
  display: flex;
  flex-wrap: wrap;
  gap: 2vh;
}

.insertDataForm > * {
  flex: 1 1 30%;
}

.insertDataAcctions {
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
}

.insertDataInputs h3 {
  padding-top: 20px;
  padding-bottom: 10px;
}

.insertDataUpload {
  border-radius: 9px;
  border: 7px solid #084b98;
}

.insertDataUpload input {
  display: none;
}

.insertDataUpload label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #44484c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusNegative {
  color: #8b0000;
  font-size: 14;
  font-weight: bold !important;
}

.statusPositive {
  color: #006400;
  font-size: 14;
  font-weight: bold !important;
}

.navigatorRobot {
  position: absolute;
  right: 1vw;
  top: 1vh;
}

.turnOnRobot {
  position: absolute;
  left: 1vw;
  top: 1vh;
}

body {
  background: #242424;
}

.darkMode h1 {
  color: #fff;
}

.darkMode .MuiDataGrid-cellContent {
  color: #fff;
}

.darkMode .MuiDataGrid-columnHeaderTitle {
  color: #fff;
}

.darkMode .MuiButtonBase-root {
  color: rgb(0, 255, 200);
}

.lightMode .MuiButtonBase-root {
  color: #0a1b35;
}

.darkMode .MuiDataGrid-selectedRowCount {
  color: #fff;
}

.darkMode .versionInfo {
  color: #fff;
}

.darkMode h1 {
  color: #fff;
}

/* .darkMode {
  background: #242424;
  clip-path: circle(133.4% at 5% 93%);
} */

.darkMode .header2 {
  border-bottom: 1px solid #545454;
  background: #303030;
}

.darkMode .logo {
  border-bottom: 1px solid #545454;
  background: #303030;
}

.darkMode .box {
  background: #363636;
  border: 1px solid #545454;
  color: #fff;
}

.darkMode .navbar {
  background: #363636;
  border: 1px solid #545454;
}

.darkMode .navbar li a:hover {
  background: #3c3c3c;
}

.darkMode .navbar li {
  border-bottom: 1px solid #545454;
}

.darkMode .versionInfo {
  color: #fff;
}

.darkMode .toggleSideBar svg {
  color: #fff;
}

.darkMode .infoBox {
  background: #363636;
  border: 1px solid #545454;
}

.darkMode .moduleTitle h1 {
  color: #fff;
}

.darkMode .profileBox p {
  color: #fff;
}

.darkMode .navbar li a {
  color: #fff;
}

.darkMode .logo {
  color: rgb(0, 255, 200);
}

.darkMode .profileBox span {
  background: #333;
}

.darkMode input:checked + .slider {
  background: rgb(0, 255, 200);
}

.darkMode input:focus + .slider {
  box-shadow: 0 0 1px rgb(0, 255, 200);
}

.darkMode .navbar li a.active {
  background: rgb(0, 255, 200);
  color: #2b2b2b;
}

.darkMode .boxHeader {
  border-bottom: 1px solid #545454;
  background: #303030;
}

.darkMode .profileBox {
  background: #303030;
  border: 1px solid #545454;
}

.darkMode .profileDropDown {
  background: #363636;
  border: 1px solid #545454;
  color: #fff;
}

.darkMode .profileDropDown ul li a:hover {
  background: #3c3c3c;
}

.darkMode .profileDropDown ul li {
  border-bottom: 1px solid #545454;
}

.darkMode .profileDropDown ul li a {
  color: #fff;
}

.darkMode .MuiInputBase-root {
  color: #fff;
}

.darkMode .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  color: rgb(0, 255, 200);
}

.darkMode .MuiOutlinedInput-notchedOutline {
  border-color: #545454;
}

.darkMode .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 255, 200) !important;
}

.darkMode .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 255, 200) !important;
}

.darkMode .MuiButtonBase-root.MuiTab-root {
  color: #fff;
}

.darkMode .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background: rgb(0, 255, 200);
  box-shadow: none;
  color: #2b2b2b;
}

.darkMode .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: rgb(0, 255, 200);
}

.darkMode .MuiChip-label {
  color: #fff;
}

.darkMode .MuiChip-deleteIcon {
  color: #fff !important;
}

.darkMode .MuiAutocomplete-tag {
  background-color: #303030;
}

.darkMode .MuiTabs-indicator {
  background-color: rgb(0, 255, 200);
}

.darkMode .drop-message-normal {
  color: #fff;
}

.darkMode .MuiTablePagination-root {
  color: #fff;
}

.darkMode .MuiSvgIcon-root {
  fill: #fff;
}

.darkMode .dropZone {
  border: 2px dashed #545454;
  border-radius: 6px;
}

.darkMode .ql-toolbar.ql-snow {
  border: none;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #545454;
  background: #303030;
}

.darkMode .ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #fff;
}

.darkMode table thead {
  border-bottom: 1px solid #545454;
  background: #303030;
}

.darkMode table thead tr th {
  border-bottom: 1px solid #545454;
}

.darkMode .MuiPaper-root.MuiPaper-elevation {
  background: #363636;
  color: #fff;
}

.darkMode .MuiPaper-root.MuiPaper-elevation .MuiDialogContent-root p {
  color: #fff;
}

.darkMode .ticket-comment {
  border: 1px solid #545454;
}

.darkMode .ticket-comment .ticket-comment-header {
  background: #303030;
  border-radius: 12px 12px 0 0;
}

.darkMode .MuiTableCell-root.MuiTableCell-body {
  border-bottom: 1px solid #545454;
  color: #fff;
  opacity: 0.5;
}

.darkmode.css-1260g7a span {
  color: #fff;
}

.darkMode .columnDiv {
  border: 2px dashed #545454;
  border-radius: 6px;
}

.darkMode .ql-snow.ql-toolbar button:hover,
.darkMode .ql-snow .ql-toolbar button:hover,
.darkMode .ql-snow.ql-toolbar button:focus,
.darkMode .ql-snow .ql-toolbar button:focus,
.darkMode .ql-snow.ql-toolbar button.ql-active,
.darkMode .ql-snow .ql-toolbar button.ql-active,
.darkMode .ql-snow.ql-toolbar .ql-picker-label:hover,
.darkMode .ql-snow .ql-toolbar .ql-picker-label:hover,
.darkMode .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.darkMode .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.darkMode .ql-snow.ql-toolbar .ql-picker-item:hover,
.darkMode .ql-snow .ql-toolbar .ql-picker-item:hover,
.darkMode .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.darkMode .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgb(0, 255, 200) !important;
}

.darkMode .ql-snow.ql-toolbar button:hover .ql-stroke,
.darkMode .ql-snow .ql-toolbar button:hover .ql-stroke,
.darkMode .ql-snow.ql-toolbar button:focus .ql-stroke,
.darkMode .ql-snow .ql-toolbar button:focus .ql-stroke,
.darkMode .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.darkMode .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.darkMode .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.darkMode .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.darkMode .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.darkMode .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.darkMode .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.darkMode .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.darkMode .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.darkMode .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.darkMode .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.darkMode .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.darkMode .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgb(0, 255, 200) !important;
}

.darkMode .ql-snow.ql-toolbar button:hover .ql-fill,
.darkMode .ql-snow .ql-toolbar button:hover .ql-fill,
.darkMode .ql-snow.ql-toolbar button:focus .ql-fill,
.darkMode .ql-snow .ql-toolbar button:focus .ql-fill,
.darkMode .ql-snow.ql-toolbar button.ql-active .ql-fill,
.darkMode .ql-snow .ql-toolbar button.ql-active .ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.darkMode .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.darkMode .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.darkMode .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgb(0, 255, 200) !important;
}

.darkMode .quill.messageContainer {
  border: 1px solid #545454;
  border-radius: 6px;
  width: 100%;
}

.darkMode .ql-container.ql-snow {
  border: none;
  border-radius: 0 0 6px 6px;
  background: #fff;
  color: #2b2b2b;
}

.darkMode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border: 1px solid #545454;
  background: #363636;
  border-radius: 6px;
}

.darkMode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: 1px solid #545454;
  border-radius: 6px;
}

.darkMode tfoot tr td {
  border-bottom: 1px solid #545454;
}

.darkMode .MuiButtonBase-root.MuiTableSortLabel-root:hover {
  color: rgb(0, 255, 200);
}

.darkMode .MuiDialog-paperScrollPaper {
  color: #fff;
}

.darkMode .ticket-container {
  background: #363636;
  border: 1px solid #545454;
}

.darkMode .MuiButtonBase-root.MuiTableSortLabel-root:focus {
  color: rgb(0, 255, 200);
}

.darkMode
  .MuiButtonBase-root.MuiTableSortLabel-root:hover
  .MuiTableSortLabel-icon {
  opacity: 1;
}

.darkMode .MuiTableRow-root:hover > .MuiTableCell-root.MuiTableCell-body {
  opacity: 1;
}

.darkMode .ql-snow .ql-stroke {
  stroke: #fff;
}

.darkMode .ql-snow .ql-picker {
  color: #fff;
}

.darkMode .MuiFormLabel-root {
  color: #fff;
}

.darkMode .MuiFormLabel-root.Mui-focused {
  color: rgb(0, 255, 200);
}

.darkMode .exportCSVbtn {
  border: 1px solid rgb(0, 255, 200);
  color: rgb(0, 255, 200);
}

.darkMode .exportCSVbtn:hover {
  background-color: #3c3c3c;
  border: 1px solid rgb(0, 255, 200);
}

.darkMode .simpleInputStyle {
  color: #fff;
}

.lightMode .simpleInputStyle {
  color: #2b2b2b;
}

.lightMode .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  box-shadow: none;
  color: #fff;
}

.lightMode {
  background: #fafafa;
}

.lightMode .header2 {
  border-bottom: 1px solid #d0d0d0;
  background: #ebebeb;
}

.lightMode .logo {
  border-bottom: 1px solid #d0d0d0;
  background: #ebebeb;
}

.lightMode .box {
  background: #fff;
  border: 1px solid #d0d0d0;
  color: #2b2b2b;
}

.lightMode .navbar {
  background: #fff;
  border: 1px solid #d0d0d0;
}

.lightMode .navbar li a:hover {
  background: #f9f9f9;
}

.lightMode .navbar li {
  border-bottom: 1px solid #d0d0d0;
}

.lightMode .versionInfo {
  color: #2b2b2b;
}

.lightMode .toggleSideBar svg {
  color: #2b2b2b;
}

.lightMode .infoBox {
  background: #fff;
  border: 1px solid #d0d0d0;
}

.lightMode .moduleTitle h1 {
  color: #2b2b2b;
}

.lightMode .profileBox p {
  color: #2b2b2b;
}

.lightMode .navbar li a {
  color: #2b2b2b;
}

.lightMode .logo {
  color: #0a1b35;
}

.lightMode .profileBox span {
  background: #333;
}

.lightMode input:checked + .slider {
  background-color: #0a1b35;
}

.lightMode input:focus + .slider {
  box-shadow: 0 0 1px #0a1b35;
}

.lightMode .navbar li a.active {
  background: #0a1b35;
  color: #fff;
}

.lightMode .boxHeader {
  border-bottom: 1px solid #d0d0d0;
  background: #ebebeb;
}

.lightMode .profileBox {
  background: #ebebeb;
  border: 1px solid #d0d0d0;
}

.darkMode .dataGridSimpleText {
  color: white;
}

.lightMode .profileDropDown {
  background: #fff;
  border: 1px solid #d0d0d0;
  color: #2b2b2b;
}

.lightMode .profileDropDown ul li a:hover {
  background: #f9f9f9;
}

.lightMode .profileDropDown ul li {
  border-bottom: 1px solid #d0d0d0;
}

.lightMode .profileDropDown ul li a {
  color: #2b2b2b;
}

.main {
  min-height: 100vh;
  display: flex;
}

.sidebar {
  min-height: 100vh;
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s ease-out;
}

.navbarContainer {
  padding: 24px 12px;
}

.versionInfo {
  white-space: nowrap;
}

.MuiTableCell-root.MuiTableCell-head .isSortable {
  display: flex;
}

.content {
  min-height: 100vh;
  width: 100%;
  overflow: clip;
  transition: all 0.4s ease-out;
}

.header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 12px;
}

.mareGros {
  font-size: 20px;
  font-weight: bold;
}

.logo {
  padding: 12px;

  height: 72px;
  display: flex;
  align-items: center;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
}

.container2 {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px 12px;
  height: calc(100% - 121px);
}

.box {
  border-radius: 6px;
}

.boxContent {
  padding: 12px 24px;
}

.boxHeader {
  padding: 12px 24px;
  border-radius: 6px 6px 0 0;
}

.boxHeader h2 {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
}

.toggleSideBar svg {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.moduleTitle h1 {
  font-size: 24px;
  white-space: nowrap;
}

.profileBox {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
}

.profileInfo:hover .profileDropDown {
  display: block;
}

.profileInfo:hover .profileDropDown {
  display: block;
}

.profileInfo:hover .profileBox {
  border-radius: 6px 6px 0 0;
}

.profileDropDown {
  border-top: none !important;
  border-radius: 0 0 6px 6px;
  position: absolute;
  z-index: 10;
  width: calc(100% - 2px);
  overflow: hidden;
}

.profileDropDown ul li a {
  text-decoration: none;
  padding: 16px 24px;
  display: block;
  cursor: pointer;
}

.profileDropDown ul {
  list-style: none;
}

.profileInfo {
  position: relative;
}

.profileDropDown ul li:last-child {
  border: none;
}

.profileBox p {
  margin-right: 12px;
}

.profileBox span {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
}

.profileBox span img {
  width: 100%;
}

.navbar {
  border-radius: 6px;
  overflow: hidden;
}

.navbar {
  list-style: none;
}

.navbar li:last-child {
  border: none;
}

.navbar li a {
  text-decoration: none;
  padding: 16px 24px;
  display: block;
  cursor: pointer;
}

.profileDropDown {
  display: none;
}

.dashboardInfo {
  padding: 24px 12px;
}

.infoBox {
  border-radius: 6px;
  padding: 16px 24px;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 200px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 19px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 51px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(140px);
  -ms-transform: translateX(140px);
  transform: translateX(140px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 34px;
}

.themeSwitch {
  margin-bottom: 12px;
}

.logo .toggleSideBar {
  display: none;
}

.expanded .sidebar {
  width: 0;
  min-width: 0;
  z-index: 1;
}

.expanded .content {
  width: 100%;
}

.ql-editor.ql-blank {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.gridIsh {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.loginPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginPage .box {
  max-width: 450px;
}

.gridIsh > * {
  flex: 1 1 350px;
}

.infoRapid {
  max-width: 600px;
  margin: auto;
}

.infoRapid p {
  margin-bottom: 12px;
}

.infoRapid ul {
  padding-left: 40px;
}

.infoRapid span {
  font-weight: bold;
}

.simpleInputStyle {
  padding: 6px 12px;
  outline: 1px solid #545454;
  border: none;
  background: transparent;
  border-radius: 6px;
  width: 50px;
}

.simpleTextArea {
  height: 17px;
}

.simpleInputStyle:focus {
  outline: 1px solid rgb(0, 255, 200);
}

.circleSpan {
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  color: black;
}

.darkMode .circleSpan {
  color: white;
}

.circleSpan > span {
  display: block;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
  height: 16px;
}

.infoTable {
  margin-top: 24px;
}

.circleSpan.agentCircle > span {
  background: #ffd700;
}

.circleSpan.tesaCircle > span {
  background: #6495ed;
}

.progressComplete {
  display: block;
  padding: 24px 0;
  background: rgb(0, 255, 200);
}

.progressLine {
  width: 100%;
  display: block;
  background: gray;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.progressValue {
  position: absolute;
  top: 50%;
  color: #2b2b2b;
  font-weight: bold;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progressBar {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;
}

.progressLabel {
  display: flex;
  gap: 24px;
}

.progressInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}

.progressInfoTitle {
  white-space: nowrap;
}

.progressInfoValue {
  background: rgb(0, 255, 200);
  width: 60px;
  height: 60px;
  border-radius: 50% 6px;
  color: #2b2b2b;
  font-weight: bold;
  position: relative;
}

.progressNow .progressInfoValue {
  background: gray;
  border-radius: 6px 50%;
}

.progressInfoValue > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.currentProgress {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ticket-comment .ticket-body ol,
.ticket-comment .ticket-body ul {
  padding-left: 40px;
}

.darkModeToggleLabel {
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.checkBoxDarkModeInput {
  position: absolute;
  opacity: 0;
}

.sun {
  position: absolute;
  font-size: 3rem;
  fill: #666 !important;
  rotate: -45deg;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  transform: scale(0) !important;
  transition: 1s ease !important;
}

.checkBoxDarkModeInput:checked ~ .sun {
  transform: scale(1) rotate(360deg) !important;
}

.moon {
  font-size: 3rem;
  fill: white !important;
  rotate: -45deg;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  transition: 1s ease !important;
}

.checkBoxDarkModeInput:checked ~ .moon {
  transform: rotate(360deg) scale(0);
}

.iconToggle {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background: #0a1b35;
  border-radius: 50%;
  box-shadow: inset 0 8px 60px rgba(0, 0, 0, 0.1),
    inset 0 8px 8px rgba(0, 0, 0, 0.1), inset 0 -4px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
  transition: 1s;
}

.checkBoxDarkModeInput:checked ~ .iconToggle {
  background: #f8f8f8;
}

.darkMode {
  background: #242424;
}

.togglerDarkModeLightMode {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .container2 {
    padding: 24px 0;
  }

  .container2 > .box > .MuiTabPanel-root {
    padding: 24px 0;
  }

  .ticketsDisplay > .MuiPaper-root > .MuiTable-root thead tr {
    display: none;
  }

  .ticketsDisplay > .MuiPaper-root > .MuiTable-root thead::before {
    content: "Lista tickete";
    padding: 12px;
    display: block;
  }

  .ticketsDisplay > .MuiPaper-root > .MuiTable-root tbody tr {
    display: flex;
    height: 92.85px;
    position: relative;
  }

  .ticketsDisplay > .MuiPaper-root > .MuiTable-root tr > .MuiTableCell-root {
    display: flex;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(1) {
    order: 5;
    position: absolute;
    left: 214px;
    border: none;
    margin-top: 50px;
    padding: 0 16px;
    z-index: 0;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(1)
    > svg {
    font-size: 24px !important;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(2) {
    order: 2;
    width: calc(100% - 232px);
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(3) {
    order: 3;
    display: none;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(4) {
    order: 4;
    position: absolute;
    left: 214px;
    border: none;
    margin-top: 32px;
    padding: 0 16px;
    width: calc(100% - 280px);
    z-index: 0;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(4)
    > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(5) {
    order: 1;
    width: 200px;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(5)
    > span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(6) {
    order: 6;
    display: none;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(7) {
    order: 3;
    display: none;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(8) {
    order: 8;
    position: absolute;
    left: 260px;
    border: none;
    margin-top: 50px;
    padding: 0 16px;
    z-index: 0;
  }

  .ticketsDisplay
    > .MuiPaper-root
    > .MuiTable-root
    tr
    > .MuiTableCell-root:nth-child(8)
    > svg {
    font-size: 24px !important;
  }

  .ticketingFilters {
    padding: 0 12px;
  }

  .ticketForm {
    padding: 0 12px;
  }

  .boxFilterIcon {
    display: flex;
    justify-content: end;
    padding: 6px 12px;
  }

  .ticketingFilters > div {
    flex-basis: calc(50% - 5px);
  }

  .main.expanded .content {
    height: 0;
  }

  .progressLabel {
    gap: 12px;
  }

  .sidebar {
    width: 0;
    min-width: 0;
  }

  .expanded .sidebar {
    width: 100%;
  }

  .expanded .content {
    width: 0;
  }

  /* .expanded .sidebar {
    width: 100%;
  } */

  .profileBox p {
    display: none;
  }

  .logo .toggleSideBar {
    display: block;
  }

  .ticket-answear {
    flex-direction: column;
  }

  .ticket-details-container {
    flex-direction: column;
  }

  .ticket-details-box {
    min-width: auto !important;
  }

  .ticket-answear-acction {
    gap: 12px;
  }

  .content {
    height: auto;
  }

  .dashboardInfo {
    margin-top: 0;
  }
}

.darkMode .MuiDataGrid-row--editing .MuiDataGrid-cell {
  background-color: #2b2b2b !important;
}

.darkMode .custom-box {
  color: #fff;
}

.darkMode .MuiDataGrid-row--editing {
}

.darkMode
  .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not(
    [multiple]
  )
  option,
.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not(
    [multiple]
  )
  optgroup {
  background-color: #2b2b2b !important;
}

.darkMode .MuiButtonBase-root.MuiButton-root.MuiButton-contained:disabled {
  background: rgb(77, 87, 85);
  box-shadow: none;
  color: #2b2b2b;
}

.custom-data-grid .MuiDataGrid-columnHeadersInner {
  background-color: #0a1b35 !important;
  color: white !important;
}

.darkMode .custom-data-grid .MuiDataGrid-columnHeadersInner {
  background-color: #303030 !important;
  color: white !important;
}

.hide-toolbar .ql-toolbar {
  display: none !important;
}

.filterButtonEnd {
  display: flex;
  width: 100%;
  justify-content: end;
}
div.MuiDataGrid-root {
  overflow: clip !important;
}
div.MuiDataGrid-root div.MuiDataGrid-main {
  overflow: clip !important;
}
div.MuiDataGrid-root div.MuiDataGrid-main div.MuiDataGrid-columnHeaders {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
