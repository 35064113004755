.teamsCardsContainer {
  padding: 2px;
}
.teamCard {
  border: 2px solid rgb(101, 97, 97);
  padding: 3px;
}
.membersFields {
  padding: 12px;
  display: flex;
  gap: 12px;
  width: 100%;
}
.autoCompleteMember {
  margin-right: 12px;
}
