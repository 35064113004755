.file-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin-bottom: 40px;
}

.file-card {
  width: calc(33.33% - 60px); /* Three columns layout by default */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  position: relative;
}

.uploadZone {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.uploadZone button {
  width: 50px;
}
.delete-button-container {
  position: absolute;
  top: 5px;
  right: 5px;
}

.delete-button {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
}
.toolTipIcon {
  top: 5px;

  top: 5px;
  cursor: pointer;
  position: absolute;
}
.fileCardHeader {
  margin-bottom: 16px;
}
.darkMode .Mui-selected {
  background-color: #ffffff52 !important;
}
.Mui-selected {
  background-color: #ebebeb !important;
}
.textFileName {
  word-wrap: break-word;
}
@media (max-width: 1024px) {
  .file-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .file-card {
    width: 100%;
  }
}
.viewFileIcon {
  background: none;
  border: none;
}
