.uploadBox {
    background: #fff;
    display: block;
    max-width: 600px;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    overflow: auto;
    position: relative;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.importArea {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
}

.hideForUpload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
}

.uploadBox .uploadHeader {
    background: #fff;
    color: #0a1b35;
    border: 4px dashed #0a1b35;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadBox .uploadHeader p {
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
}

.uploadBox .uploadBody {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 43px);
}

.uploadBox .uploadBody p {
    padding: 24px;
}

table thead {
    background: #0a1b35;
}

table thead th {
    color: #fff !important;
}

.containerInputs {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
}