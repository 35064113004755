.ticketDemo {
  cursor: pointer;
  padding: 12px 24px;
  background: #333;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 12px;
}

.dialogBody {
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 1300 !important;
}

.quili-box {
  /* position: absolute; */
  /* min-height: 60px; */
  background-color: #fff;
  color: #fff;
  transition: height 0.3s;
}

.darkMode .quili-box {
  background-color: #222;
}

.messageContainerChatTickets .userNameInContainer {
  font-weight: bold;
  font-size: 12px;
  margin-top: -6px;
  margin-bottom: 6px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-bottom: -1px;
  bottom: 100%;
  top: initial !important;
}

.details-top {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-top > p {
  color: black;
  margin: 0;
  top: 0;
}

.darkMode .details-top > p {
  color: #fff;
}

.saveButton {
  margin-top: 5px !important;
}

.ql-editor {
  padding: 6px 12px !important;
}

.input-message .ql-container.ql-snow {
  border-radius: 50px !important;
}

.send-message > svg {
  height: 18px;
  width: 18px;
}

.lightMode .send-message {
  /* background: #1976d2; */
}

.darkMode .send-message {
  /* background: #2c2c2e; */
}

.send-message {
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 30px;
  height: 30px;
  justify-content: center;
}

.show-quili > svg {
  height: 16px;
  width: 16px;
}

.attachmentInput > button {
  padding: 3px;
}

.send-box-container {
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 7px 12px 40px 12px;
  /* padding: 0 12px; */
  /* flex-shrink: 1; */
  display: flex;
  position: relative;

  /* flex-direction: column; */
  /* min-height: 60px; */
  /* border-top: 1px solid rgba(211, 211, 211, 0.329); */
}

.details-header > p {
  color: #fff;
  margin: 0;
  padding: 0;
}

.details-body {
  overflow-y: scroll;
  padding: 12px 0;
  border-top: 1px solid rgba(211, 211, 211, 0.329);
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  padding: 12px;
  flex-grow: 1;
  margin-right: -14px;
}

.details-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.details-info > div {
  background: #fff;
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  color: black;
}

.darkMode .info-ticket-details {
  background: #222222;
}

.info-ticket-details {
  background: #ffffff;

  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 0;
  /* width: 100%; */
  overflow: hidden;
  transition: width 0.3s ease;
}

/* .files:hover,
            .show-quili:hover,
            .send-message:hover,
            .send-message:hover,
            .go-back-btn:hover {
                opacity: 0.5;
            } */

.details-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.go-back-btn {
  height: 30px;
  width: 30px;
  background: lightgrey;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.info-ticket {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  cursor: pointer;
  border-radius: 24px;
}

.info-ticket > p {
  color: #000000;

  padding: 0;
  margin: 0;
}

.darkMode .info-ticket > p {
  color: #fff;
}

.info-ticket:hover {
  background: rgb(224, 224, 224);
}

.darkMode .info-ticket:hover {
  background: #464646;
}

.info-ticket-title {
  font-weight: 700;
}

.files {
  background: rgb(0, 0, 0);
}

#emoji-palette {
  top: -308px !important;
  left: 0 !important;
  height: 100%;
}

.input-message {
  max-width: 100%;
  position: relative;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.darkMode .send-box-container {
  background: #171717;
}

.darkMode .ql-container.ql-snow {
  border-radius: 50px !important;
  border: 1px solid rgba(74, 74, 76, 0.5);
  background: #2c2c2e;
  color: #fff;
}

.darkMode .ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.6) !important;
}

.input-message > input {
  border: 1px solid rgba(211, 211, 211, 0.329);
  background: #464646;
  border-radius: 50px;
  height: 32px;
  outline: none;
  font-size: 16px;
  padding: 0 12px;
  color: #fff;
  text-size-adjust: 100%;
}

.boxChat {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}

/* Media query for screens smaller than 600px */

.phone {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* position: absolute;
                top: 0; */
  /* overflow: hidden; */
}

.message-header {
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  flex-shrink: 1;
  display: flex;
  min-height: 60px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.329);
}

.messages {
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  padding: 7px;
  flex-grow: 1;
  max-width: 100%;
}

.darkMode .messages {
  background: #464646;
}

.darkMode .messageContainerChatTickets > div > h1 {
  color: black;
}

.messageContainerChatTickets {
  background: #464646;
  padding: 12px;
  border-radius: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  border: 1px solid rgb(195, 194, 194);
  align-self: flex-start !important;
}

.sentByMe .messageAvatar {
  display: none;
}

.messageAndAvatar {
  display: flex;
}

.messages .sentByMe .messageContainerChatTickets {
  border-radius: 12px 12px 0 12px;
}

.messages .messageContainerChatTickets {
  border-radius: 12px 12px 12px 0;
}

.messages .messageContainerChatTickets {
  background: white;
  padding: 10px;
  color: black;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.messageContainerChatTickets > div > ol {
  padding: 20px;
}

.messageContainerChatTickets > div > ul {
  padding: 20px;
}

.commentDate {
  margin-top: 0.4rem;
  text-align: end;
  font-weight: 400;
  color: #828080;
  font-size: 12px;
}

.sentByMe .messageContainerChatTickets {
  background-color: #dcf8c6 !important;
}

.messageAvatar {
  background: #333;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.messageAvatar img {
  width: 100%;
}

.messageContainerBox {
  display: flex;
  align-items: end;
  gap: 7px;
  max-width: 80%;
  position: relative;
}

.sentByMe {
  align-self: flex-end !important;
}

.comment {
  word-wrap: break-word;
}

.commentDate {
  margin-top: 0.4rem;
  text-align: end;
}

.senderUserMessage {
  font-weight: 700;
  margin-bottom: 5px;
  max-width: 100%;
  font-size: 14px;
}

.fileIconOnChat {
  border-radius: 20px;
  background-color: gray;
  color: white;
}

.react-quill {
  width: 100%;
  height: 100%;
}

.fileNameWithIcon {
  word-wrap: break-word;
  cursor: pointer;
}

.messageImageContainer > img {
  width: 100%;
}

/* pentru detalii ticket */
.ticketDetailsTrue .message-header,
.ticketDetailsTrue .messages,
.ticketDetailsTrue .send-box,
.ticketDetailsTrue .info-ticket-details {
  width: 100%;
}

@media (min-width: 768px) {
  .boxChat {
    width: 90vw;
  }

  .ticketDetailsTrue .message-header,
  .ticketDetailsTrue .messages,
  .ticketDetailsTrue .bottomSendText {
    width: calc(100% - 350px);
  }

  .ticketDetailsTrue .info-ticket-details {
    width: 350px;
  }
}

@media (min-width: 1024px) {
  .boxChat {
    width: 80vw;
  }
}

.ticketingBodyDialog .MuiDialog-container > .MuiPaper-root {
  max-width: 100% !important;
}

.message-header,
.messages,
.send-box {
  transition: width 0.3s ease;
}

/* pentru detalii ticket */

#quili-box {
  transition: height 0.3s ease;
}

.ticketFilesBox {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .dialogBody {
    /* z-index: -100; */
    padding: 0 !important;
    margin: 0 !important;
  }
  .comment {
    max-width: 200px;
  }
  .ql-editor {
    padding-right: 30px;
  }

  .phone {
    height: 100vh;
  }

  .message-header {
    padding: 0 12px;
    min-height: 60px;
    position: sticky;
    background: #ffffff;

    top: 0;
    z-index: 1;
  }

  .darkMode .message-header {
    background: #333;
  }

  .messages {
    flex-grow: 1;
    overflow-y: auto;
  }

  .send-box-container {
    /* padding-bottom: 80px; */
  }

  .send-box-container .files,
  .send-box-container .show-quili {
    height: 30px;
    width: 30px;
    font-size: 16px;
  }

  .send-box-container .files,
  .send-box-container .show-quili {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .details-info > div {
    padding: 12px;
  }
}

.show-quili {
  position: absolute;
  right: 60px;
  cursor: pointer;
  z-index: 999;
  /* left: 1px;
  z-index: 999;
  top: 50%;
  bottom: 50%;
  transform: translate(0, -50%); */
}

.quill {
  max-width: 100%;
}

.ql-editor {
  padding-right: 30px;
}

.ql-editor > p {
  max-width: 100%;
  max-height: 50px;
  overflow: auto;
}

.attachmentInput {
  cursor: pointer;
}

.send-box {
  border: 1px solid rgba(211, 211, 211, 0.329);
}

.darkMode .send-box {
  border: none;
}
.bottomSendText {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #171717;
  position: relative;
}
/* .darkMode .send-box-container {
  background: #171717;
} */

.lightMode .bottomSendText {
  background-color: #fff;
}
.richTextEditor {
  width: 200px !important;
  flex-grow: 1;
}
.icon-container {
  cursor: pointer;
  width: 25px;
  align-self: flex-end;
  fill: white;
  padding: 5px;
  margin-bottom: 5px;
}
.lightMode .icon-container {
  fill: #333;
}
.icon-container2 {
  z-index: 9999;
  position: absolute;
  right: 45px;
  bottom: 10px;
  cursor: pointer;
  width: 25px;
  align-self: flex-end;
  fill: white;
}

/* div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  height: 200px;
  width: 100%;
  overflow-y: auto;
} */
/* .input-message > input {
  border: 1px solid rgba(211, 211, 211, 0.329);
  background: #464646;
  border-radius: 50px;
  height: 32px;
  outline: none;
  font-size: 16px;
  padding: 0 12px;
  color: #fff;
  text-size-adjust: 100%;
} */
