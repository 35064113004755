.MainDash{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    display: flex;
}

@media screen and (max-width: 1200px) {
    .MainDash{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .MainDash{
        align-items: center;
    }
}