.fromToDate {
  display: flex;
  gap: 12px;
}
.exportBancaBtn {
  margin-top: 5px !important;
  padding: 0px !important;
}
.editToolbarSa {
  display: flex;
  gap: 5px;

  background-color: #303030;
}
