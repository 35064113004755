.ticketForm .rdw-editor-toolbar,
#ticketModal .rdw-editor-toolbar {
  opacity: 0;
  transition: opacity 0.3s ease;
  /* Smooth fade-in transition */
  display: flex !important;
  padding: 0px !important;
}

.ticketForm .toolbar-visible .rdw-editor-toolbar,
#ticketModal .toolbar-visible .rdw-editor-toolbar {
  opacity: 1;
  top: 0;
  /* Transition toolbar to top of viewport */
  padding: 0px !important;
}

.ticketForm .toolbar-hidden .rdw-editor-toolbar,
#ticketModal .toolbar-hidden .rdw-editor-toolbar {
  max-height: 0px;
  transition: max-height 0.3s ease;
  /* Smooth fade-in transition */

  pointer-events: none;
  /* Disable interactions while hidden */
}

.ticketForm .rdw-editor-wrapper,
#ticketModal .rdw-editor-wrapper {
  margin: 5px;
  border: 1px solid rgba(211, 211, 211, 0.329);
  border-radius: 20px;
  padding: 0px !important;
}

.darkMode #ticketModal .rdw-editor-wrapper {
  background: #464646;
}

.ticketFormticketModal .rdw-editor-wrapper,
.lightMode #ticketModal .rdw-editor-wrapper {
  border: 1px solid rgba(55, 52, 52, 0.268);

  background: #ffff;
}

.ticketForm .public-DraftStyleDefault-block,
#ticketModal .public-DraftStyleDefault-block {
  margin: 0.5rem 0px;
}

.ticketForm .rdw-dropdownoption-default,
#ticketModal .rdw-dropdownoption-default {
  background-color: #464646;
}

.ticketForm .rdw-dropdown-selectedtext,
#ticketModal .rdw-dropdown-selectedtext {
  color: #464646;
}

.ticketFormicketModal .rdw-colorpicker-modal,
.darkMode #ticketModal .rdw-colorpicker-modal {
  background-color: #464646;
}

.ticketForm .rdw-emoji-modal,
#ticketModal .rdw-emoji-modal,
.ticketForm .rdw-colorpicker-modal,
#ticketModal .rdw-colorpicker-modal,
.ticketForm .rdw-dropdown-optionwrapper,
#ticketModal .rdw-dropdown-optionwrapper {
  top: auto;
  bottom: 100%;
}

.ticketForm .rdw-dropdown-optionwrapper,
#ticketModal .rdw-dropdown-optionwrapper {
  top: -250px;
}

#ticketForm .rdw-history-wrapper,
#ticketModal .rdw-history-wrapper,
#ticketForm .rdw-link-wrapper,
#ticketModal .rdw-link-wrapper,
#ticketForm .rdw-remove-wrapper,
#ticketModal .rdw-remove-wrapper,
#ticketForm .rdw-embedded-wrapper,
#ticketModal .rdw-embedded-wrapper,
#ticketForm .rdw-fontfamily-wrapper,
#ticketModal .rdw-fontfamily-wrapper,
#ticketForm .rdw-text-align-wrapper,
#ticketModal .rdw-text-align-wrapper,
.ticketForm .rdw-image-wrapper,
#ticketModal .rdw-image-wrapper {
  display: none;
}

.ticketForm div[title="Subscript"],
#ticketModal div[title="Subscript"],
.ticketForm div[title="Superscript"],
#ticketModal div[title="Superscript"],
.ticketForm div[title="Monospace"],
#ticketModal div[title="Monospace"],
.ticketForm div[title="Strikethrough"],
#ticketModal div[title="Strikethrough"] {
  display: none;
}

.ticketForm .DraftEditor-editorContainer,
#ticketModal .DraftEditor-editorContainer {
  padding: 0px 35px 0px 10px;
}

@media (max-width: 600px) {

  .ticketForm .rdw-emoji-modal,
  #ticketModal .rdw-emoji-modal {
    left: -160px;
  }
}